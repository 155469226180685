.map-container {
    background-color: #282c34;
    /* min-height: 80vh;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white; */
    
    
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
}

#popup {
    /* width: 300px;
    height: 150px; */
    margin-top: 4px;
    border: 1px solid maroon;
    border-radius: 10px;
    color: maroon;
    background-color: #FFF;
    position: static;
    opacity: 1;
    padding: 4px 6px;
    box-shadow: #282c3499 3px 3px 3px;
    transition: color 100ms ease;
    transition: background-color 100ms ease;
}

#popup h5 {
    margin: 0;
}

@media (prefers-color-scheme: dark) {
    #popup {
        border: 1px solid #FFF;
        color: #FFF;
        font-weight: normal;
        background-color: #282c34;
    }
  }