:root {
    --almost-clear: #D6D9AC;
    --green-yellow: #C7CA83;
    --platinum-yellow: #D7CD86;
    --pale-yellow: #E2D890;
    --pale-gold: #E6B14C;
    --deep-gold: #E8A016;
    --pale-salmon: #E9A372;
    --deep-pink: #CD2D50;
    --deep-salmon: #D3302B;
    --pale-ruby: #8E0811;
    --deep-violet: #3C030A;
    --deep-purple: #1C0508;
    --tawny: #892507;
}

.checkin-note {
    display: none;

    &.show {
        display: block;
    }

    & p {
        padding-bottom: 5px;
    }
}

.nearbySlides {
    .swiper-pagination {
        bottom: -10px;
        padding-bottom: 4px;
    }
}

.avatar {
    color: #FFF;
    background: #800;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 110px;
    height: 110px;
    font-weight: 600;
    font-size: 50px;
}

.friend-avatar {
    color: #FFF;
    background: #800;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
}

.notification-dot {
    position: absolute;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    right: -0.70em;
    top: 0.70ex;
    // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.join-on-map {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 70px;

    &.open {
        bottom: 150px;
    }

    & :first-child {
        margin-right: 10px;
    }
}

.attachmentCard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
}

.attachementWrapper {
    position: relative;
    display: flex;
    width: 32%;
    aspect-ratio: 1;
    border: 0.5px solid;
    overflow: hidden;
    margin-bottom: 2%;

    .delete {
        position: absolute;
        top: 5px;
        right: 5px
    }

    .label {
        background-color: #800;
        color: white;
        position: absolute;
    }

    .loader {
        position: absolute;
        // background-color: #999;
        top: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        z-index: -1;
    }

    .photo {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    button {
        background-color: transparent;
    }
}

.swiper.photoSlider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.ion-flex {
    display: flex !important;
}

.ion-flex-col {
    flex-direction: column;
}

.ion-no-padding-left {
    padding-left: 0;
}

.ion-no-padding-right {
    padding-right: 0;
}

.ion-no-padding-top {
    padding-top: 0;
}

.ion-no-padding-bottom {
    padding-bottom: 0;    
}

.ion-size-md-sm {
    font-size: 25px;
}

.small-text {
    font-size: 0.8em;
}

.line-break {
    white-space: normal;
}

ion-reorder.inline {
    display: inline;
}

.segment-button-checked.ios::part(native) {
    color: var(--ion-color-primary);
}

// .rating-segment {
//   background-color: transparent;
// }

// ion-segment-button.ios::part(indicator-background) {
//   background: var(--ion-color-primary-contrast);
// }
.wine-chip {
    background-color: transparent;
    margin-right: 12px;
    padding-inline: 6px;
}

.action-sheet-button {
    background-color: #dbdbdb;
    // border: #acacac solid 1px;
    border-radius: 5px;
    color: #656565;
}

@media (prefers-color-scheme: dark) {
    .segment-button-checked.ios::part(native) {
        color: var(--ion-color-primary);
    }

    // ion-segment-button.ios::part(indicator-background) {
    //   background: var(--ion-color-step-300);
    // }

    // .rating-segment {
    //   background-color: transparent;
    // }

    .wine-chip {
        background-color: #383838;
    }

    .action-sheet-button {
        background-color: #414141;
        color: #acacac;
    }
}

@media screen and (max-width: 400px) {
    .custom-popover::part(content) {
        width: 80vw !important;
    }
}

.vino-markdown p {
    margin: 0.5em 0;
}

.dialogModal {
    --width: fit-content;
    --min-width: 350px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

    & .wrapper {
        margin-bottom: 12px;
    }
}
