.center-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-badge {
    --background: rgb(7, 107, 29);
    --color: white;
    --padding-end: 20px;
    --padding-start: 20px;
}

.pop-heading {
    padding-bottom: 14px;
}

.custom-button:active {
    background-color: lightgray;
    border-radius: 10px;
}

.unread {
    position: absolute;
    top: 0px;
    right:-5px;
    min-height: 5px;
    min-width: 5px;

    background-color: white;
    color: #000;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
}

.segmentContent {
    height: 65vh;
}
