.listing-gallery-modal {

  .swiper-modal img {
      height: 100%;
      width: 100%;
      margin: auto;
      align-items: center;
      justify-content: center;
      object-fit: contain;
  }

  .swiper-wrapper {
    flex-basis: auto;
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
}
