.mapContainer {
  background-color: #282c34;
  /* min-height: 80vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
  
  
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}