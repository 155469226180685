.menuHeader {
    background-color: white;
    .logo {
        height: 40px;
    }
}
.logReg {
    padding-top: 20px;
}
.logReg > * {
    margin: 0px 0px 20px 0px;
}
.sideMenu {
    --side-max-width: 265px;
}