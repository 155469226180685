.mapPage .swiper-slide {
    width: 285px !important;
}
.mapPage .swiper-slide ion-card-content {
    min-height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.locationCard{
    // height: 18vh;
    width: 100%;
    margin: 5px;

    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.5em;
    }
}

.circle {
    border: 1px solid var(--ion-color-primary);
    // border-radius: 50%;
    // padding: 4px 9px;

    width: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 1em;

}

.listingBannerGradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, 1) 100%);
}

.segment {
    background-color: #333333;
    width: 200px;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.slideContainer {
    position: absolute;
    transition: bottom 0.25s ease-out;
    overflow: visible;
    width: 100%;

    .swiper {
        overflow: visible;
    }

}

.slideContainer.showSlider {
    bottom: 15px;
}

.slideContainer.hideSlider {
    bottom: -127px;
}

.mapPage .swiper-slide-active {
    transform: scale(1.03) !important;

    ion-card {
        border: 1px solid var(--ion-color-primary);
    }
}

.mapPage ion-fab-button,
.mapPage ion-button {
    filter: drop-shadow(7px 7px 14px rgba(0, 0, 0, 0.25));
}

.mapPage .swiper-slide-active ion-card {
    filter: drop-shadow(0px 20px 28px rgba(0, 0, 0, 0.25));
}

@media (prefers-color-scheme: dark) {
    .listingBannerGradient {
        // --gradient-from: rgba(255, 255, 255, .2);
      // --gradient-to: rgba(255, 255, 255, 1);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, .2) 100%);
    }
}