.option-group-label {
  .alert-button-inner {
    .alert-radio-icon {
      display: none;
    }
  }

  .alert-radio-label {
    padding-left: 26px;
  }
}
