.header {
  position: fixed;
  top: 0px;
  text-align: center;
}

.slide {
  overflow: auto;
  color: black;

  .wrapper {
    // nudging content down under header
    padding-top: 57px;
    text-align: center;
    }

  .thumbnail {
    width: 30px;
    height: 35px;
    
    justify-content: center;
    .img {
      width: unset;
      padding: 5px;
    }
  }
  
  // wineglass thumbnails are bigger sized & will get cropped if used with the .thumbnail setting
  .thumbnail-wine {
    width: 32px;
    height: 47px;
    display: center;
    justify-content: center;
    .img {
      width: unset;
      padding: 5px;
    }
  }

  .image {
    height: 260px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .gridRow{
    padding: 5px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 16px;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .swiper-container {
    width: 100%;
    height: 100vh;
    display: flex;
}

  .swiper-container {
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    background-color: maroon;
  }

  .welcomeListItem {
    font-size: 14px;
    padding-left: 2px;
    padding-top: 2px;
  }

  .welcomeHeading4 {
    font-size: 14px;
    padding-left: 2px;
    padding-top: 5px;
  }
}

.checkin-list-individual {
  display: block;
    height: 363px;
    margin-left: auto;
    margin-right: auto;
}

.checkin-summary-individual {
  display: block;
    height: 363px;
    margin-left: auto;
    margin-right: auto;
}

ion-col .center {
  display: flex;
  flex-direction: column;
  align-items: center;
}