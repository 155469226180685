.swiperImage {
    padding: 5px;
    padding-left: 0px;
    padding-right: 10px;
    height: 150px;
    width: auto;
    object-fit: cover;
    margin-top: auto;
    margin-bottom: auto;
}

.swiperImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 100% // opacity: 1;
}

.swiperImageContainer img {
    margin-top: auto;
    margin-bottom: auto;
}


.swiper-glide {
    flex-basis: fit-content;
}

.swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.statusLabel {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

ion-list ion-item:last-child {
    --border-width: 0 0 0 0 !important;
    border-width: 0 !important;
}

ion-item-group:last-child ion-item:last-child .item-inner {
    --border-width: 0 0 0 0 !important;
    border-width: 0 !important;
}

.listing-map-container {
    height: 200px;
}

.swiper {
    height: 100%;
}

.listingCheckinSwiperImage {
    margin: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 70%;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 20px;
    min-height: 50px;
    padding: 5px;
}

.overlay {

    & h3,
    & h6,
    & p {
        margin: 0px 0px;
        margin-top: 2px;
    }
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--ion-color-primary);
    color: white;
    padding: 30px;
}

.hide-nav {
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}

.topOverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.customToolbar {
    --background: transparent;
}

.centeredContent {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}
